import { Component } from '@angular/core';
import { CsiConfig } from '@qivos/qivoscloud-ion-components/lib/models/csi.model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() {}
  startingPageConfiguration: CsiConfig = {
    logo: '',
    welcomeMsg: 'Your experience matters!',
    title: 'Dear customer,',
    mainText:
      'We value your experience with our product, so we encourage you to answer some short questions with your feedback.',
    questionnaireUrlText: 'LET’S START!',
    footerText: 'The questionnaire will be available for the next 20 days.',
    questionsData: [
      'How would you rate the quality of our product?',

      'How would you rate the customer experience?',

      'How would you rate your general experience and satisfaction with our product?',
    ],
    numericQuestionDescription:
      'From a scale of 1-10 how would you rate our product?',

    successMsg: 'Thank you for the feedback!',
    successText:
      'We will continue to evolve the quality and customer experience of our product.',
    expiredMsg: 'We are sorry.',
    expiredText: 'This page has expired.',
    submitButtonText: 'SEND',
    buttonColorText: '#FF0000',
    siteUrl: '',
    account: 'demo',
    language: 'en',
    formId: '',
    iconsValue: ['VERY BAD', 'BAD', 'AVERAGE', 'GOOD', 'EXCELLENT'],
  };
}
